<template>
  <!-- Contamination artificielle -->
  <div class="general-information-subview">
    <teleport to="#header-action">
      <Btn
        v-if="helperService.userHasPermission('essaievenement_oedit')"
        class="action-btn"
        icon="create"
        color="white"
        hollow
        text="Modifier"
        :to="{
           name: 'essaiExperimentalTaskArtificialContaminationEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid
          }
        }"
      />
    </teleport>
  </div>

  <div class="tabs-material-subtitle">
    <Container>
      <h2>Produit contaminant</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue
      table
      label="Produit contaminant"
      :value="fiche?.produitcontaminant"
    />
  </div>
</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ArtificialContaminationSubview',
  components: {
    Container,
    Btn,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fiche: {},
    }
  },

  emits: ['set-editable'],

  mounted() {
    this.getFiche()
  },
  methods: {
    async getFiche() {
      this.fiche = await this.taskService.getFiche(this.$route, 'contamination')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
